module.exports = {
  siteMetadata: {
    title: `Accurity`,
    description: `Accurity by Simplity`,
    siteUrl: `https://www.accurity.ai`,
    author: `Simplity`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-less`,
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /icons/,
        },
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Accurity`,
        short_name: `Accurity`,
        start_url: `/`,
        background_color: `#0c2340`,
        theme_color: `#0c2340`,
        display: 'browser',
        icon: `src/images/favicon-256x256.png`,
      },
    },
    {
      resolve: 'gatsby-plugin-cookiebot',
      options: {
        cookiebotId: 'c77839d0-d67d-481f-af72-daf914a5a4a8', // Required. Site's Cookiebot ID.
        manualMode: false, // Optional. Turns on Cookiebot's manual mode. Defaults to false.
        blockGtm: true, //  Optional. Skip blocking of GTM. Defaults to true if manualMode is set to true.
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-KZCVXN7',
      },
    },
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'NriQKIRpIjYjqtRW48KQ2Qtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveRelations: [
          'ctaBanner',
          'ctaBanner2',
          'Testimonial Slider.testimonials',
          'referenceLogos',
          'locationBlocks',
          'customerStory',
          'testimonial',
          'blogAuthor',
          'blogCards',
          'reportsCards',
          'resources',
          'caseStudiesCards',
          'webinarsCards',
          'onDemandWebinarsCards',
          'featuredBlog',
          'TopNav Menu Item.promotedItem',
          'customerStory',
          'form',
          'articles',
          'presenters',
          'logoStripe'
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        exclude: [`/editor/`],
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage {
              nodes {
                context {
                  story {
                    content
                  }
                }
                path
              }
            }
          }
        `,
        serialize: ({ site, allSitePage }) => {
          const siteMapEntries = [];

          // Add HP
          siteMapEntries.push({
            url: `${site.siteMetadata.siteUrl}`,
            changefreq: 'daily',
            priority: 0.7,
          })

          // PDF Pages
          ;[
            { url: '/cookies-policy.pdf', changefreq: 'monthly' },
            { url: '/gdpr.pdf', changefreq: 'monthly' },
            { url: '/privacy-policy.pdf', changefreq: 'monthly' },
            { url: '/security-policy.pdf', changefreq: 'monthly' },
            { url: '/terms-of-service.pdf', changefreq: 'monthly' },
            { url: '/marketing-consent.pdf', changefreq: 'monthly' },
          ].forEach(pdfPage => {
            siteMapEntries.push({
              url: `${site.siteMetadata.siteUrl}${pdfPage.url}`,
              changefreq: pdfPage.changefreq,
              priority: 0.5,
            })
          });

          // Storyblok Pages
          allSitePage.nodes.forEach(node => {
            const content =
              node.context && node.context.story && node.context.story.content
                ? JSON.parse(node.context.story.content)
                : undefined;

            const isNoIndex =
              content && content.seoMeta && content.seoMeta.noIndex;
            const changefreq =
              content && content.seoMeta && content.seoMeta.changefreq
                ? content.seoMeta.changefreq
                : 'daily';
            const priority =
              content && content.seoMeta && content.seoMeta.priority
                ? content.seoMeta.priority
                : 0.7;

            // add page only when noindex field is not set, it is not homepage or thank you page (homepage is added above)
            if (
              !isNoIndex &&
              node.path !== '/' &&
              !node.path.includes('thank-you')
            ) {
              siteMapEntries.push({
                url: `${site.siteMetadata.siteUrl}${node.path}`,
                changefreq: changefreq,
                priority: Number(priority),
              })
            }
          });

          return siteMapEntries
        },
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
};
